import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../redux/user/userSlice";
import { deleteGame } from "../redux/asyncThunk/userActions";
import { useNavigate } from "react-router-dom";
import NewGame from "./NewGame";

const { hideDialog } = userSlice;

export default function Dialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const userId = useSelector((state) => state.user?.data?._id);
  const { type } = useSelector((state) => state.user?.showDialog);

  const switchCase = (type) => {
    switch (type) {
      case "new":
        return <NewGame />;
      case "delete":
        return (
          <div
            style={{
              padding: "15px 20px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <div style={{ textAlign: "center" }}>Подтвердите удаление</div>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={() => dispatch(hideDialog())} size="small">
                Отменить
              </Button>
              <Button
                color="error"
                size="small"
                variant="contained"
                onClick={() => {
                  let onSucces;

                  onSucces = () => {
                    navigate("/main", { replace: true });
                  };

                  dispatch(
                    deleteGame({ userId: userId, id: selectedGameId, onSucces })
                  );
                }}
              >
                Удалить
              </Button>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        minWidth: "300px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        border: "1px solid black",
        borderRadius: "10px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 10px 0 0",
        }}
      >
        <CloseIcon
          sx={{ cursor: "pointer" }}
          onClick={() => dispatch(hideDialog())}
        />
      </div>
      {switchCase(type)}
    </div>
  );
}
