import { combineReducers, configureStore } from "@reduxjs/toolkit";
import user from "./user/userSlice";

const reducer = combineReducers({
  user: user,
});

export const store = configureStore({
  reducer: (state, action) => {
    return reducer(state, action);
  },
});
