import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import SimplePeer from "simple-peer";

const socket = io("http://localhost:3005");

const VideoPlayer = () => {
  const [startP, setStartP] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const peerRef = useRef(null);

  useEffect(() => {
    if (startP) {
      const peer = new SimplePeer({
        initiator: true,
        trickle: false,
        stream: null,
      });

      peer.on("signal", (data) => {
        socket.emit("signal", data);
      });

      peer.on("data", (data) => {
        setVideoChunks((prevChunks) => [...prevChunks, data]);
      });

      socket.on("signal", (data) => {
        peer.signal(data);
      });

      socket.on("videoChunk", (chunk) => {
        console.log(chunk);
        setVideoChunks((prevChunks) => [...prevChunks, chunk]);
      });

      socket.on("videoEnd", () => {
        const blob = new Blob(videoChunks, { type: "video/mp4" });
        setVideoBlob(blob);
      });

      peerRef.current = peer;

      return () => {
        peer.destroy();
      };
    }
  }, [startP]);

  const startCall = () => {
    setStartP(true);
    socket.emit("requestVideo");
  };

  return (
    <div>
      {videoBlob && (
        <video controls>
          <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
        </video>
      )}
      <button onClick={startCall}>Request Video</button>
    </div>
  );
};

export default VideoPlayer;
