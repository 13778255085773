import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MovingIcon from "@mui/icons-material/Moving";
import GradeIcon from "@mui/icons-material/Grade";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import VideoChatOutlinedIcon from "@mui/icons-material/VideoChatOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckIcon from "@mui/icons-material/Check";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function MainPage() {
  return (
    <div style={{ backgroundColor: "#FCFCFC", color: "#305478" }}>
      <div className="main_block">
        <div style={{ display: "flex" }}>
          <div className="main_text">
            <div>
              <h1
                style={{
                  fontSize: "40px",
                  fontWeight: "bolder",
                  letterSpacing: "3px",
                }}
              >
                MOGAME
              </h1>
              <div>-</div>
              <h1 style={{ letterSpacing: "5px" }}>
                Создавай свой уникальный контент в телеграме
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "60px", padding: "30px 0" }}>
        <div className="column_list">
          <div className="column_list_item">
            <div className="subtitle">
              <GradeIcon
                sx={{
                  fontSize: "60px",
                  color: "#305478",
                  border: "2px solid #305478",
                  borderRadius: "50%",
                  padding: "5px 5px",
                }}
              />
            </div>

            <ul>
              <li className="list_item">Уникальный контент</li>
              <li className="list_item">Простор для креатива и ваших идей</li>
            </ul>
          </div>
          <div className="column_list_item">
            <div className="subtitle">
              <MovingIcon
                sx={{
                  fontSize: "60px",
                  color: "#305478",
                  border: "2px solid #305478",
                  borderRadius: "50%",
                  padding: "5px 5px",
                }}
              />
            </div>

            <ul>
              <li className="list_item">Активное и пассивное продвижение</li>
              <li className="list_item">Рост количества подписчиков</li>
            </ul>
          </div>
          <div className="column_list_item">
            <div className="subtitle">
              <AttachMoneyIcon
                sx={{
                  fontSize: "60px",
                  color: "#305478",
                  border: "2px solid #305478",
                  borderRadius: "50%",
                  padding: "5px 5px",
                }}
              />
            </div>

            <ul>
              <li className="list_item">Монетизация на вашей площадке</li>
              <li className="list_item">
                Монетизация через нашу и партнёрские площадки{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="block">
        <div
          style={{
            display: "flex",
            maxWidth: "300px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <CheckIcon
                sx={{
                  fontSize: "60px",
                  color: "#305478",
                  padding: "5px 5px",
                }}
              />
            </div>
            <div style={{ fontSize: "20px" }}>Бесплатно</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <SupportAgentIcon
                sx={{
                  fontSize: "60px",
                  color: "#305478",
                  padding: "5px 5px",
                }}
              />
            </div>
            <div style={{ fontSize: "20px" }}>Тех. поддержка</div>
          </div>
        </div>
      </div>
      <div className="block">
        <div>
          <div className="title">
            <h2>О продукте</h2>
          </div>
          <div style={{ maxWidth: "1250px", width: "100%" }}>
            <video controls width={"100%"}>
              <source src="/images/flowers-3.mp4" type="video/webm" />
            </video>
          </div>
        </div>
      </div>

      <div className="block">
        <div>
          <div className="title">
            <h2>Создавай свои сценарии и пусть пользователи пройдут по нему</h2>
          </div>

          <div className="column_list">
            <div className="column_list_item">
              <div className="subtitle">
                Реализуй свой сценарий или воспользуйся примерами
              </div>
              <ReceiptLongOutlinedIcon sx={{ fontSize: "50px" }} />
            </div>
            <div>
              <ArrowForwardIosIcon sx={{ fontSize: "30px" }} />
            </div>
            <div className="column_list_item">
              <div className="subtitle">
                Подготовь видео-контент и настрой сцены
              </div>
              <VideoChatOutlinedIcon sx={{ fontSize: "50px" }} />
            </div>
            <div>
              <ArrowForwardIosIcon sx={{ fontSize: "30px" }} />
            </div>
            <div className="column_list_item">
              <div className="subtitle">
                Предоставляй индивидуальный или групповой доступ
              </div>
              <GroupOutlinedIcon sx={{ fontSize: "50px" }} />
            </div>
            <div>
              <ArrowForwardIosIcon sx={{ fontSize: "30px" }} />
            </div>
            <div className="column_list_item">
              <div className="subtitle">Получай доход</div>
              <MonetizationOnIcon sx={{ fontSize: "50px" }} />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div>
          <div className="title">
            <h2>Создавать это просто</h2>
          </div>
          <img
            src="/images/tg1.png"
            alt="tg-pic"
            style={{
              width: "100%",
              maxWidth: "1220px",
              maxHeight: "715px",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}
