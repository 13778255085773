import React, { useEffect } from "react";

const TelegramAuth = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?7";
    script.setAttribute("data-telegram-login", "@RR_232_test_bot"); // Замените на имя вашего бота
    script.setAttribute("data-size", "large");
    script.setAttribute("data-radius", "10");
    script.setAttribute("data-auth-url", "https://club-lab:3004/auth"); // URL вашего сервера
    script.setAttribute("data-request-access", "write");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="telegram-widget-container"></div>;
};

export default TelegramAuth;
