import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import VideoPlayer from "./VideoComp";
import ErrorIcon from "@mui/icons-material/Error";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";

export default function TelegramChat() {
  const messages = useSelector((state) => state.user.messages);
  const [data, setData] = useState([]);

  useEffect(() => {
    const temp = [...messages];

    setData(temp.reverse());
  }, [messages]);

  return (
    <div className="tgArea">
      <VideoPlayer />
      {data?.reverse().map((el, i) => {
        return (
          <div>
            <div className="tgMessage" key={el + i}>
              <div>{el.message}</div>

              {el.type === "error" ? (
                <ErrorIcon sx={{ color: "red" }} />
              ) : (
                <DoneOutlineIcon sx={{ color: "green" }} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
