import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NodeToolbar } from "reactflow";
import { loadVideoScene } from "../../redux/asyncThunk/userActions";
import { userSlice } from "../../redux/user/userSlice";
import "reactflow/dist/style.css";
import "../index.css";

import { Box, TextField, Button } from "@mui/material";

const { addNode, addMessage } = userSlice;

export const firstSceneNodes = [
  {
    id: "1-group",
    type: "group",
    position: { x: 0, y: 0 },
    style: {
      backgroundColor: "rgba(45, 157, 188, 0.5)",
      width: 200,
      height: 160,
    },
  },
  {
    id: "1",
    position: { x: 0, y: 13 },
    data: { video: "" },
    type: "first-scene-node",
    className: "light",
    style: {
      backgroundColor: "rgba(125, 157, 188, 0.5)",
      width: 200,
      height: 100,
    },
    extent: "parent",
    parentNode: "1-group",
    parentId: "1-group",
    draggable: false,
  },
];

export function FirstSceneNode(props) {
  const { data } = props;
  const selectedGameId = useSelector((state) => state.user?.selectedGameId);
  const game = useSelector((state) => state.user?.game);
  const [video, setVideo] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [showTextInput, setShowTextInput] = useState(false);

  const dispatch = useDispatch();

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    dispatch(loadVideoScene({ selectedGameId, scene: 1, file }));
  };

  const handleAddButtonNode = () => {
    const checkButtonsInNode = game.nodes.find((el) => el.type === "input");

    const checkButtonsLength = game.nodes.filter((el) => el.type === "input");

    if (checkButtonsLength.length === 2) {
      return dispatch(
        addMessage({
          type: "error",
          message: "Сейчас вы можете добавить только две кнопки",
        })
      );
    }

    const buttonPosiiton = {
      x: 0,
      y: 0,
    };

    const newId = !checkButtonsInNode
      ? "1-1"
      : `1-${Number(checkButtonsInNode.id.split("-")[1]) + 1}`;

    if (!checkButtonsInNode) {
      buttonPosiiton.y = 140;
    } else {
      buttonPosiiton.y = 117;
    }

    const newButtonNode = {
      id: newId,
      data: { label: buttonText },
      position: buttonPosiiton,
      style: {
        width: 200,
        height: 20,
      },
      parentId: "1-group",
      sourcePosition: "right",
      type: "input",
      extent: "parent",
      parentNode: "1-group",
      draggable: false,
    };

    dispatch(addNode({ data: [newButtonNode] }));
  };

  return (
    <>
      <NodeToolbar isVisible={undefined}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              marginBottom: "40px",
            }}
          >
            {showTextInput ? (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  id="outlined-basic"
                  label="Текст кнопки"
                  variant="outlined"
                  size="small"
                  onChange={(event) => setButtonText(event.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddButtonNode()}
                >
                  Добавить
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <button
                className="toolbar_button"
                onClick={() => setVideo(!video)}
              >
                Добавить видео
              </button>
              {video ? (
                <input
                  type="file"
                  name="videoFile"
                  accept="video/*"
                  onChange={handleVideoChange}
                />
              ) : null}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <button
                className="toolbar_button"
                onClick={() => setShowTextInput(!showTextInput)}
              >
                Добавить кнопку
              </button>
            </div>
            {/* <button className="toolbar_button">Удалить</button> */}
          </Box>
        </Box>
      </NodeToolbar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div
          style={{
            height: "140px",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2F5478",
            width: "200px",
          }}
        >
          {data?.hasVideo ? (
            <video
              style={{ borderRadius: "50%", width: "100px", height: "70px" }}
              controls
            >
              <source src={data.videoUrl} type="video/mp4" />
            </video>
          ) : (
            "Загрузите видео"
          )}
        </div>
      </div>
    </>
  );
}
