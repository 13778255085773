import { useEffect, useState } from "react";
import "./index.css";
import InputFileUpload from "./UploadFile";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Button, TextField, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";

export default function Settings() {
  const [data, setData] = useState();
  const [settings, setSettings] = useState({
    title: "",
    access: "Приватный",
    status: "В работе",
    description: "",
  });

  const game = useSelector((state) => state.user.game);

  const handleChange = (event, property) => {
    const temp = { ...settings };
    temp[property] = event.target.value;
    setSettings(temp);
  };

  useEffect(() => {
    if (game) {
      setData({
        ...game,
        scenesValue: game.nodes.filter((el) => el.type === "group")?.length,
      });
    }
  }, [game]);

  return (
    <div
      style={{
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        height: "1000px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2>Настройки</h2>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "1440px",
          marginTop: "30px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="settingsRow">
            <div>Название проекта</div>
            <div>
              <TextField
                onChange={(event) => handleChange(event, "title")}
                id="title"
                label="Введите название"
                variant="outlined"
                size="small"
                value={settings?.title}
                sx={{
                  width: "300px",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <InputFileUpload
            // handleAddPoster={handleAddPoster}
            // id={el._id}
            />
          </div>
          <div className="settingsRow">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>Доступ к проекту</span>
              <Tooltip
                title="Приватный доступ - пользователи получат доступ к вашему сценарию только с кодом который можете дать только вы. Публичный доступ - пользователи смогут получить доступ через партнёрские площадки"
                placement="top"
              >
                <InfoOutlinedIcon
                  sx={{ cursor: "pointer", fontSize: "14px" }}
                />
              </Tooltip>
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ width: "300px", justifyContent: "space-between" }}
                value={settings.access}
                id="access"
                onChange={(event) => handleChange(event, "access")}
              >
                <FormControlLabel
                  value="Публичный"
                  control={<Radio />}
                  label="Публичный"
                />
                <FormControlLabel
                  value="Приватный"
                  control={<Radio />}
                  label="Приватный"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>Статус проекта</span>
              <Tooltip
                title="Статус В работе означает что доступа к сценарию у пользователей нет"
                placement="top"
              >
                <InfoOutlinedIcon
                  sx={{ cursor: "pointer", fontSize: "14px" }}
                />
              </Tooltip>
            </div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={settings.status}
                onChange={(event) => handleChange(event, "status")}
                sx={{ width: "300px", justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value="Опубликовать"
                  control={<Radio />}
                  label="Опубликовать"
                />
                <FormControlLabel
                  value="В работе"
                  control={<Radio />}
                  label="В работе"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="settingsRow">
            <div>Стартовый пост</div>
            <TextField
              id="description"
              label="Введите текст и сохраните проект после редактирования"
              variant="outlined"
              multiline="true"
              minRows={"3"}
              width="300px"
              sx={{
                width: "300px",
              }}
              onChange={(event) => handleChange(event, "description")}
            />
          </div>
          <div className="settingShortRow">
            <div>Количество сцен:</div>
            <div>{data?.scenesValue}</div>
          </div>
          <div className="settingShortRow">
            <div>Лимит доступов:</div>
            <div>{data?.limit}</div>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <h4>Стартовый пост вашей игры</h4>
          <div
            style={{
              border: "2px solid black",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "400px",
              minHeight: "350px",
              flexDirection: "column",
              margin: "3px 0",
            }}
          >
            <h4>MoGaMe</h4>
            <div
              className="image-preview"
              style={{
                boxSizing: "border-box",
                height: "220px",
                width: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data?.poster ? (
                <img
                  src={`data:image/jpeg;base64,` + data?.poster}
                  alt="Uploaded"
                />
              ) : (
                <Tooltip placement="top" title="Постер не загружен">
                  <ImageNotSupportedOutlinedIcon />
                </Tooltip>
              )}
            </div>
            {settings?.description || settings.title ? (
              <div
                style={{
                  width: "90%",
                  border: "1px solid #305478",
                  borderRadius: "10px",
                  backgroundColor: "#305478",
                  color: "white",
                  padding: "3px 0",
                }}
              >
                <p>{settings.title}</p>
                <p>{settings.description}</p>
              </div>
            ) : null}
            <Button variant="contained" size="small" sx={{ margin: "3px 0" }}>
              Запустить
            </Button>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <h4>Пример стартового поста</h4>
          <div
            style={{
              border: "2px solid black",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "400px",
              minHeight: "350px",
              flexDirection: "column",
            }}
          >
            <h4>MoGaMe</h4>
            <div
              className="image-preview"
              style={{
                height: "220px",
                width: "300px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={process.env.PUBLIC_URL + "/images/bg.png"}
                alt="Background"
              />
            </div>
            <div
              style={{
                width: "90%",
                border: "1px solid #305478",
                borderRadius: "10px",
                backgroundColor: "#305478",
                color: "white",
                padding: "3px 0",
                margin: "3px 0",
              }}
            >
              <p>Долг платежом красен</p>
              <p>
                Привет! Ты готов начать увлекательное приключение со
                мной?Привет! Ты готов начать увлекательное приключение со мной?
              </p>
            </div>
            <Button variant="contained" size="small" sx={{ margin: "3px 0" }}>
              Запустить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
