import "./App.css";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import MainPage from "./pages/MainPage";
import PersonalPage from "./pages/PersonalPage";
import { Routes, Route, Link, Outlet, useNavigate } from "react-router-dom";
import { loadAuth } from "./redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { userSlice } from "./redux/user/userSlice";
import TelegramAuth from "./components/TelegramLoginButton.js";

const { exitHandle, resetSelectedProject } = userSlice;

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (!user) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/main/*" element={<PersonalPage />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  // let name = "@RR_232_test_bot"; // Это имя бота который вы ранее создавали в BotFather
  // const handleBot = (user) => {
  //   console.log(user);
  // };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  return (
    <div>
      <header className="header">
        <div style={{ maxWidth: "250px", width: "100%", textAlign: "center" }}>
          <Link
            to={user ? "/main" : "/"}
            onClick={() => dispatch(resetSelectedProject())}
            style={{ textDecoration: "none" }}
          >
            <span
              style={{
                cursor: "pointer",
                color: "white",
                fontWeight: "bolder",
                fontSize: "18px",
              }}
            >
              MoGaMe
            </span>
          </Link>
        </div>

        <div
          style={{
            maxWidth: "250px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {user?.tgId ? (
            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <div
                style={{ color: "white", fontWeight: "bold", fontSize: "16px" }}
              >
                {user.tgId}
              </div>
              <LogoutIcon
                sx={{ cursor: "pointer", color: "white" }}
                onClick={() => dispatch(exitHandle())}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <Link to={"/main"}>
                <Button
                  sx={{ bgcolor: "white" }}
                  variant="outlined"
                  onClick={() => dispatch(loadAuth())}
                >
                  Войти
                </Button>
              </Link>
              {/* <Link to={"/main"}>
                <Button
                  sx={{ bgcolor: "white" }}
                  variant="outlined"
                  onClick={() => dispatch(loadAuth())}
                >
                  Демо-вход
                </Button>
              </Link> */}
              <TelegramAuth
              // botName={name}
              // buttonSize="large" // "large" | "medium" | "small"
              // cornerRadius={3} // 0 - 20
              // usePic={false} // true | false
              // dataOnauth={handleBot}
              />
            </div>
          )}
        </div>
      </header>

      <Outlet />
    </div>
  );
}

export default App;
