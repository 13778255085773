import { createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_SERVER_URL || "http://localhost:3004";

export const getGame = createAsyncThunk(`user/getGame`, async function (props) {
  const { gameId } = props;

  const response = await fetch(`${url}/game/${gameId}`);

  const game = await response.text();

  return JSON.parse(game);
});

export const addNewGame = createAsyncThunk(
  "user/addNewGame",
  async function (props) {
    const { userId, textProject, onSuccess } = props;

    const response = await fetch(`${url}/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        textProject,
      }),
    });

    const result = await response.text();

    onSuccess?.();

    return JSON.parse(result);
  }
);

export const deleteGame = createAsyncThunk(
  "user/deleteGame",
  async function (props) {
    const { userId, id, onSucces } = props;

    const response = await fetch(`${url}/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
      }),
    });

    const result = await response.text();

    if (result) {
      onSucces?.();

      return JSON.parse(result);
    }
  }
);

export const saveGame = createAsyncThunk(
  "user/saveGame",
  async function (props) {
    const { selectedGameId, nodes, edges } = props;

    const response = await fetch(`${url}/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          selectedGameId,
          nodes,
          edges,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);

export const loadVideoScene = createAsyncThunk(
  `user/addVideoScene`,
  async function (props) {
    const { selectedGameId, file, scene } = props;

    const formData = new FormData();

    formData.append("videoFile", file);

    const response = await fetch(`${url}/upload/${selectedGameId}/${scene}`, {
      method: "POST",
      body: formData,
    });

    const result = await response.text();

    return JSON.parse(result);
  }
);

export const loadPoster = createAsyncThunk(
  `user/loadPoster`,
  async function (props) {
    const { gameId, base64String, onSucces } = props;

    const formData = new FormData();
    formData.append("imageFile", base64String);

    const response = await fetch(`${url}/upload/${gameId}`, {
      method: "POST",
      body: formData,
    });

    const result = await response.text();

    if (result) {
      onSucces?.();

      return JSON.parse(result);
    }
  }
);

export const deletePoster = createAsyncThunk(
  "user/deletePoster",
  async function (props) {
    const { gameId, onSucces } = props;

    const response = await fetch(`${url}/deletePoster/${gameId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.text();

    if (result) {
      onSucces?.();

      return JSON.parse(result);
    }
  }
);

export const getVideo = createAsyncThunk(
  `user/getVideo`,
  async function (props) {
    const { selectedGameId } = props;

    const response = await fetch(`${url}/video/${selectedGameId}`);

    const result = await response.text();

    return JSON.parse(result);
  }
);

export const addDescription = createAsyncThunk(
  "user/addDescription",
  async function (props) {
    const { selectedGameId, description } = props;

    const response = await fetch(`${url}/description`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          selectedGameId,
          description,
        },
      }),
    });

    const result = await response.text();
    return JSON.parse(result);
  }
);
