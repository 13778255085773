import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { getGamesData } from "../redux/user/userSlice";
import { userSlice } from "../redux/user/userSlice";
import {
  loadPoster,
  // addDescription,
  deletePoster,
} from "../redux/asyncThunk/userActions";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import InputFileUpload from "./UploadFile";
import "../index.css";

const { selectProject } = userSlice;

export default function Dashbord() {
  const dispatch = useDispatch();

  const [gamesData, setGamesData] = useState([]);

  const data = useSelector((state) => state.user?.games);

  const handleAddPoster = (event, id) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64String = reader.result.split(",")[1];

      dispatch(
        loadPoster({
          gameId: id,
          base64String,
          onSucces: () => dispatch(getGamesData()),
        })
      );
    };
  };

  // const handleDescription = (str, gameId) => {
  //   const updateGame = data.map((el) => {
  //     if (el._id === gameId) {
  //       return {
  //         ...el,
  //         description: str,
  //       };
  //     } else {
  //       return el;
  //     }
  //   });

  //   setGamesData(updateGame);
  // };

  useEffect(() => {
    dispatch(getGamesData());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setGamesData(data);
    }
  }, [data]);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}
      >
        <h1>Рабочий стол</h1>
      </div>

      {gamesData?.length ? (
        <div
          style={{
            display: "flex",
            gap: "20px",
            padding: "20px 20px",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {gamesData?.map((el) => {
            return (
              <div
                key={el._id}
                style={{
                  border: "2px solid #2F5478",
                  width: "340px",
                }}
              >
                <div style={{ textAlign: "center", padding: "5px 0" }}>
                  {el.title}
                </div>
                {/* <div style={{ textAlign: "center", padding: "5px 0" }}>
                  {el._id}
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {el.poster ? (
                    <div
                      className="image-preview"
                      style={{
                        boxSizing: "border-box",
                        height: "220px",
                        width: "300px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={`data:image/jpeg;base64,` + el.poster}
                        alt="Uploaded"
                      />
                    </div>
                  ) : (
                    <div className="upload-container">
                      <div className="upload-icon">
                        <NoPhotographyIcon />
                      </div>
                      {/* <div className="upload-text">Загрузите постер</div> */}
                    </div>
                  )}

                  {el.poster ? (
                    <Tooltip title="Удалить постер" placement="top">
                      <DeleteIcon
                        onClick={() => {
                          dispatch(
                            deletePoster({
                              gameId: el._id,
                              onSucces: () => dispatch(getGamesData()),
                            })
                          );
                        }}
                        sx={{
                          position: "absolute",
                          right: 25,
                          top: 5,
                          color: "red",
                          cursor: "pointer",
                          border: "1px solid red",
                          borderRadius: "3px",
                          bgcolor: "white",
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>

                <div style={{ padding: "5px 3px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px 15px",
                    }}
                  >
                    <Tooltip title="Настройки проекта" placement="top">
                      <Link
                        to={`/main/${el._id}`}
                        state={{ projectId: el._id, type: "settings" }}
                      >
                        <SettingsIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => dispatch(selectProject(el._id))}
                        />
                      </Link>
                    </Tooltip>
                    <InputFileUpload
                      handleAddPoster={handleAddPoster}
                      id={el._id}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <div>
            <div>Вы пока еще не создали свой первый проект</div>
          </div>
        </div>
      )}
    </div>
  );
}
